<template>
  <div>
    <div class="d-flex flex-row mb-0">
      <p class="text-2xl mb-6">Prebid by pubstack options</p>
    </div>
    <v-card class="mb-6 pa-0">
      <v-card-text>
        Attention: Une modification ici n'impactera les imports de CA qu'à
        partir du lendemain.
      </v-card-text>
    </v-card>
    <v-row>
      <v-col cols="6">
        <v-card class="mb-6 pa-2">
          <v-card-text>
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">CA pris en compte</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in listActivated"
                    :key="item.id"
                    :value="item.var_name"
                  >
                    <td class="pb-3">
                      <v-switch
                        @click="confirmSaveMeta(item)"
                        v-model="item.var_value_int"
                        :label="item.var_name"
                        hide-details
                        color="primary"
                        :loading="item.loading"
                        readonly
                      ></v-switch>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card class="mb-6 pa-2">
          <v-card-text>
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">CA non pris en compte</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in listDeactivated"
                    :key="item.id"
                    :value="item.var_name"
                  >
                    <td class="pb-3">
                      <v-switch
                        @click="confirmSaveMeta(item)"
                        v-model="item.var_value_int"
                        :label="item.var_name"
                        hide-details
                        readonly
                        color="primary"
                        :loading="item.loading"
                      ></v-switch>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from "@axios";
import store from "@/store";

export default {
  name: "GamPrebidPubstack",
  async created() {
    const departmentList = await this.$store.dispatch(
      "common/getDepartmentList"
    );
    const prog_dept = departmentList.find(
      (department) => department.name === "Programmatique"
    );
    this.departmentId = prog_dept?.id ?? 0;
    this.getMetas();
  },
  data() {
    return {
      departmentId: 0, // 2 => Programmatique,
      varGroup: "gam_prebid_pubstack_key_value_list",
      list: [],
      // listActivated: [],
      // listDeactivated: [],
    };
  },
  methods: {
    async confirmSaveMeta(item) {
      await store.dispatch("app/setDialog", {
        show: true,
        type: "confirm",
        width: 800,
        title: "Confirmer",
        text: `Êtes vous certain de vouloir ${
          item.var_value_int == 1 ? "dé" : ""
        }activer ${item.var_name} ?`,
        code: null,
        // callbackFunction: this.saveMeta,
        callbackFunction: () => {
          this.saveMeta(item);
        },
      });
    },
    async saveMeta(item) {
      let new_var_value_int = item.var_value_int === 0 ? 1 : 0;
      const params = {
        department_id: this.departmentId,
        var_group: this.varGroup,
        var_name: item.var_name,
        var_value_int: new_var_value_int,
      };
      item.loading = true;
      const response = await axios.put("/metas/save", params);
      item.loading = false;
      if (response.status === 200) {
        await store.dispatch("app/setSnackBar", {
          show: true,
          text: `Le CA de "${item.var_name}" ${
            item.var_value_int === 0
              ? "sera désormais"
              : "ne sera désormais plus"
          } pris en compte.`,
          color: "success",
          timeout: 3000,
        });
      } else {
        await store.dispatch("app/setSnackBar", {
          show: true,
          text: `Une erreur est survenue à l'enregistrement de la prise en compte ou non du CA "${item.var_name}".`,
          color: "error",
          timeout: 3000,
        });
      }
      this.getMetas();
    },
    async getMetas() {
      const queryParams = {
        department_id: this.departmentId,
        var_group: this.varGroup,
      };
      const {
        data: { items: responseItems },
      } = await axios.get("/metas/list-by-group-site", {
        params: queryParams,
      });
      for (let i in responseItems) {
        responseItems[i]["loading"] = false;
      }
      this.list = responseItems;
    },
  },
  computed: {
    listActivated() {
      return this.list
        .filter((item) => item.var_value_int === 1)
        .sort((a, b) => a.var_name.localeCompare(b.var_name));
    },
    listDeactivated() {
      return this.list
        .filter((item) => item.var_value_int === 0)
        .sort((a, b) => a.var_name.localeCompare(b.var_name));
    },
  },
  // watch: {
  //   list() {
  //     this.listDeactivated = this.list
  //       .filter((item) => item.int_value === 0)
  //       .sort((a, b) => a.var_value.localeCompare(b.var_value));

  //     this.listActivated = this.list
  //       .filter((item) => item.int_value === 1)
  //       .sort((a, b) => a.var_value.localeCompare(b.var_value));
  //   },
  // },
};
</script>
